export class AppConfig {
    private static urlLRApi;
    private static urlLRApiRfy;
    private static urlSig;

    public static obterUrlLRApi() {
        return this.urlLRApi;
    }

    public static definirUrlLRApi(url) {
        this.urlLRApi = url;
    }

    public static obterUrlLRApiRfy() {
        return this.urlLRApiRfy;
    }

    public static definirUrlLRApiRfy(url) {
        this.urlLRApiRfy = url;
    }

    public static obterUrlSig() {
        return this.urlSig;
    }

    public static definirUrlSig(url) {
        this.urlSig = url;
    }
}